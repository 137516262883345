<template lang="pug">
div
  FileDropZone(v-if="checkAccess('recordBookStatement', 'add_file')" ref="mediaContent").w-100.my-4
  v-btn(color="success" :loading="buttonLoader" @click="uploadFile").my-4 {{ $t('btn.save') }}
</template>

<script>
import FileDropZone from '@/components/atoms/DropZone/DropZone.vue'
import { postFiles } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
export default {
  name: 'SailorRecordBookStatementUploadFiles',
  props: {
    sailorDocument: Object
  },
  components: {
    FileDropZone
  },
  data () {
    return {
      buttonLoader: false,
      postFiles,
      checkAccess
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId
    })
  },
  methods: {
    ...mapActions(['uploadFilesSailorPassportStatements']),
    /** Upload file to record book statement */
    async uploadFile () {
      this.buttonLoader = true
      let data = {
        ...this.$route.params,
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_sailor_passport'
        }
      }
      if (checkAccess('recordBookStatement', 'view_file')) {
        const response = await this.uploadFilesSailorPassportStatements(data)
        this.buttonLoader = false
        if ([200, 201].includes(response.code)) {
          this.$notification.success('notify.success.addedFiles')
          this.$parent.$parent.getData()
        }
      }
    }
  }
}
</script>
